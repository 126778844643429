import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import useDetectClose from "hooks/dropDown";
import { Turn as Hamburger } from 'hamburger-react'

// 이미지
import OfficeIcon from '../../asset/img/office_ic.png'
import CosmeticIcon from '../../asset/img/cosmetic_ic.png'
import CompanyIcon from '../../asset/img/company_ic.png'

const MoHeaderLayout = (props, ref) => {
  const [menuIsOpen, menuRef, menuHandler] = useDetectClose(false);

  return (
    <>
      <Header>
        <Inner>
          <Hamburger toggled={menuIsOpen} toggle={menuHandler} $isDrop={menuIsOpen} $menu={props.$menu} rounded
                     size={30} distance="sm"/>
          <Link to="/">
            <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/common/ust_ic.svg" alt="ust vina"/>
          </Link>
        </Inner>
        <DropMenu $isDrop={menuIsOpen}>
          <MenuList>
            <MenuItem>
              <Item>
                <NavLink to="/office" className={({ isActive }) => {
                  return isActive ? 'active' : '';
                }} onClick={menuHandler}>
                  <Ic src={OfficeIcon}/>
                  <MenuName>Văn Phòng</MenuName>
                </NavLink>
              </Item>
            </MenuItem>
            <MenuItem>
              <Item>
                <NavLink to="/cosmetic" className={({ isActive }) => {
                  return isActive ? 'active' : '';
                }} onClick={menuHandler}>
                  <Ic src={CosmeticIcon}/>
                  <MenuName>Sắc đẹp & sống</MenuName>
                </NavLink>
              </Item>
            </MenuItem>
            <MenuItem>
              <Item>
                <NavLink to="/info" className={({ isActive }) => {
                  return isActive ? 'active' : '';
                }} onClick={menuHandler}>
                  <Ic src={CompanyIcon}/>
                  <MenuName>Về UST VINA</MenuName>
                </NavLink>
              </Item>
            </MenuItem>
          </MenuList>
        </DropMenu>
      </Header>
      <Dimmed $isDrop={menuIsOpen}/>
    </>
  );
};
// 모바일 스타일 컴포넌트
const Header = styled.div`
  z-index: 10;
  position: sticky;
  top: 0;
  height: 55px;
  background: #ffffff;
  box-shadow: 0 5px 5px 0 rgba(165, 165, 165, 0.15);
`

const Inner = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > .hamburger-react {
    position: absolute !important;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  & > a {
    max-width: 168px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    & img {
      width: 80%;
    }
  }
`

const DropMenu = styled.div`
  z-index: 1;
  position: absolute;
  top: 55px;
  width: 100%;
  padding: 20px 40px;
  opacity: 0;
  background: #f2f2f2;
  visibility: hidden;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  ${({ $isDrop }) =>
          $isDrop &&
          css`
            opacity: 1;
            visibility: visible;
          `};
`

const MenuList = styled.ul``

const MenuItem = styled.li`
  &:nth-child(n+2) {
    & > div {
      border-top: 1px solid #e0e0e0;
    }
  }
`

const Item = styled.div`
  padding: 10px;

  & a {
    display: flex;
    align-items: center;
    gap: 0 10px;
  }

  & .active span {
    color: #0067B0;
  }
`

const Ic = styled.img.attrs({ alt: '드랍다운 아이콘' })`
`

const MenuName = styled.span`
  font-weight: 400;
  font-size: 18px;
  color: #000000;
`

const Dimmed = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.7);

  ${({ $isDrop }) =>
          $isDrop &&
          css`
            opacity: 1;
            visibility: visible;
          `};
`

export default MoHeaderLayout;
