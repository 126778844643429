import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";

import styled from 'styled-components';

import AOS from 'aos';
import 'aos/dist/aos.css';

/* JS */
import * as Server from '../asset/js/Server';

/* 메인 컴포넌트 */
import MainVisual from 'components/main/MainVisual';
import MainBenefit from 'components/main/MainBenefit';
import MainDataCnt from 'components/main/MainDataCnt';
import MainFloor from 'components/main/MainFloor';
import MainGallery from 'components/main/MainGallery';
import MainLease from 'components/main/MainLease';
import MainMap from 'components/main/MainMap';
import MainBanner from 'components/main/MainBanner';

const VinaHome = () => {
  const isDeskTop = useMediaQuery({ minWidth: 1241 });

  const [mainItem, setMainItem] = useState({
    banner: [],
    gallery: [],
    office: [],
  });

  useEffect(() => {
    AOS.init({ duration: 1000 });

    Server.sendGet(
      'vina/main/list',
      {
        bannerType: 'M',
      },
      getMainList
    ).then();
  }, []);

  const onPhone = useOutletContext();

  const getMainList = res => {
    setMainItem(res['resultObject']);
  };

  return (
    <>
      <Content>
        <MainVisual item={mainItem?.banner}/>
        <MainBenefit/>
        <MainDataCnt/>
        {isDeskTop && <MainFloor/>}
        <MainGallery item={mainItem?.gallery}/>
        <MainLease function={onPhone} item={mainItem?.office}/>
        <MainMap/>
        <MainBanner/>
      </Content>
    </>
  );
};

const Content = styled.div`
  z-index: 1;
  position: relative;
`;

export default VinaHome;
