import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

/* 글로벌 css */
import './App.css';
import GlobalStyles from 'common/GlobalStyles';

/* UST Vina Components */
import VinaLayout from 'components/layout/layout';

/* UST Vina Page */
import VinaHome from 'page/index';
import VinaOffice from 'page/office';
import VinaCosmetic from 'page/cosmetics';
import VinaInfo from 'page/info';
import HealThaEtc from 'etc/health';
import CosmeticDetail from "./components/cosmetic/CosmeticDetail";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Routes>
        <Route element={<VinaLayout/>}>
          <Route path="/" element={<VinaHome/>}/>
          <Route path="/office" element={<VinaOffice/>}/>
          <Route path="/cosmetic" element={<VinaCosmetic/>}/>
          <Route path="/cosmetic/detail" element={<CosmeticDetail/>}/>
          <Route path="/info" element={<VinaInfo/>}/>
        </Route>
        <Route path="/healthaclean" element={<HealThaEtc/>}/>
      </Routes>
      <GlobalStyles/>
    </>
  );
}

export default App;
