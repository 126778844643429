import React from 'react';
import styled from 'styled-components';

const MainMap = () => {
  return (
    <>
      <Container>
        <Inner>
          <MapBox data-aos="fade-up">
            <MapView
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3833.858154939805!2d108.19792827595845!3d16.072848839339326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31421851957575f9%3A0x7db512fbb23c07f1!2zNjM3LCA2MzkgTmd1eeG7hW4gVOG6pXQgVGjDoG5oLCBYdcOibiBIw6AsIFRoYW5oIEtow6osIMSQw6AgTuG6tW5nIDU1MDAwMCDrsqDtirjrgqg!5e0!3m2!1sko!2skr!4v1690792769698!5m2!1sko!2skr"
              allowFullScreen=""
              width="100%"
              loading="lazy"
            />
            <MapText>637 - 639 Nguyen Tat Thanh - Thanh Khe District - Da Nang</MapText>
          </MapBox>
        </Inner>
      </Container>
    </>
  );
};

/* 스타일 컴포넌트 */

const Container = styled.div`
  position: relative;
`;

const Inner = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 100px 0 180px;

  @media only screen and (max-width: 1240px) {
    max-width: none;
    padding: 20px 16px 40px;
  }
`;

const MapBox = styled.div`
  position: relative;

  @media only screen and (max-width: 1240px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

const MapView = styled.iframe`

  height: 520px;

  @media only screen and (max-width: 1240px) {
    width: 100%;
    height: 250px;
  }
`;

const MapText = styled.span`
  padding: 30px 0;
  display: block;
  width: 100%;
  background: #1e81ce;
  font-weight: 700;
  font-size: 26px;
  color: #ffffff;
  text-align: center;

  @media only screen and (max-width: 1240px) {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
  }

  @media only screen and (max-width: 460px) {
    font-size: 13px;
  }


`;

export default MainMap;
