import styled from 'styled-components';
import { useMediaQuery } from "react-responsive";

/* 회사소개 컴포넌트 */
import InfoTab from 'components/info/InfoTab';
import HeaderLayout from "../components/layout/header";
import React from "react";

const VinaInfo = () => {
  const isDeskTop = useMediaQuery({ minWidth: 1240 })

  return (
    <>
      <Content>
        <Visual>
          <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/visual/visual_info.png" alt="회사정보"/>
        </Visual>
        <InfoWrap>
          {isDeskTop &&
            <MapSec>
              <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/vina_map.png" alt="세계지도 비나"/>
            </MapSec>
          }
          <InfoTab/>
        </InfoWrap>
      </Content>
    </>
  );
};

const Content = styled.div`
  z-index: 1;
  position: relative;
`;

const Visual = styled.div`
  position: relative;
  max-width: 1280px;
  margin: 0 auto;

  & img {
    width: 100%;
  }
`;

const InfoWrap = styled.div`
  position: relative;
`;

const MapSec = styled.section`
  padding: 130px 0 60px;
  position: relative;
  max-width: 1280px;
  margin: 0 auto;

  & img {
    width: 100%;
  }
`;

export default VinaInfo;
