import { useState, useEffect } from 'react';
import styled from 'styled-components';

/* JS */
import * as Common from '../../asset/js/Common';

const OfficeMap = props => {
  const [tabIndex, setTab] = useState(0);

  useEffect(() => {
    if (!Common.isEmpty(props?.item)) {
      setTab(props?.item?.length - 1);
    }
  }, [props?.item]);

  const changeTab = menuIndex => {
    setTab(menuIndex);
  };

  return (
    <>
      <OfficeSec>
        <Inner>
          <OfficeBox>
            <OfficeNav>
              {props?.item?.map((item, index) => (
                <NavItem key={index} tab={tabIndex === index} onClick={() => changeTab(index)}>
                  <ItemDesc tab={tabIndex === index}>
                    <strong>{item.floor}F</strong>
                    <span>{item['title']}</span>
                  </ItemDesc>
                </NavItem>
              ))}
            </OfficeNav>
            <OfficeTab>
              <OfficeImg src={props?.item?.[tabIndex]?.['imgUrl']}/>
            </OfficeTab>
          </OfficeBox>
        </Inner>
      </OfficeSec>
    </>
  );
};

const OfficeSec = styled.div`
  position: relative;
`;

const Inner = styled.div`
  padding: 110px 0 80px;
  max-width: 1240px;
  margin: 0 auto;

  @media only screen and (max-width: 1240px) {
    padding: 40px 16px;
  }
`;

const OfficeBox = styled.div`
  border-top: 1px solid #000000;

  display: flex;
  align-items: flex-start;
  gap: 0 32px;
`;

const OfficeNav = styled.ul`
  margin-top: 50px;
  width: calc(50% - 16px);
  border-top: 1px solid #a8a8a8;

  @media only screen and (max-width: 480px) {
    margin-top: 20px;
  }
`;

const NavItem = styled.li`
  cursor: pointer;
  border-right: ${props => (props.tab ? '1px solid #1e81ce' : '1px solid #a8a8a8')};
  border-bottom: ${props => (props.tab ? '1px solid #1e81ce' : '1px solid #a8a8a8')};
  background: ${props => (props.tab ? '#1e81ce' : '#ffffff')};

  & strong {
    min-width: 60px;
    display: block;
    font-weight: 700;
    font-size: 36px;
    color: ${props => (props.tab ? '#ffffff' : '#000000')};
  }

  & span {
    font-weight: 500;
    font-size: 28px;
    color: ${props => (props.tab ? '#ffffff' : '#000000')};
  }

  @media only screen and (max-width: 1024px) {
    border-left: ${props => (props.tab ? '1px solid #1e81ce' : '1px solid #a8a8a8')};
    & strong {
      font-size: 28px;
    }

    & span {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 780px) {
    & strong {
      min-width: 40px;
      font-size: 20px;
    }

    & span {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 780px) {
    & strong {
      min-width: 30px;
      font-size: 16px;
    }

    & span {
      font-size: 12px;
    }
  }
`;

const ItemDesc = styled.div`
  position: relative;
  padding: 25px 20px;
  display: flex;
  align-items: center;
  gap: 0 45px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 39px;
    height: 39px;
    border-radius: 50%;
    background: ${props =>
            props.tab
                    ? '#ffffff url("https://ust-vina.s3.ap-northeast-2.amazonaws.com/office/floor_ic_w.svg") 50% 50% no-repeat'
                    : '#1e81ce url("https://ust-vina.s3.ap-northeast-2.amazonaws.com/office/floor_ic.svg") 50% 50% no-repeat'};
  }

  @media only screen and (max-width: 1024px) {
    padding: 20px;
  }

  @media only screen and (max-width: 780px) {
    padding: 15px;
    gap: 0 16px;
    &::before {
      display: none;
    }

    @media only screen and (max-width: 480px) {
      padding: 10px;
    }
`;

const OfficeTab = styled.div`
  padding: 30px 0;
  width: calc(50% - 16px);
  text-align: center;

  @media only screen and (max-width: 1240px) {
    padding: 40px 0;
  }

  @media only screen and (max-width: 480px) {
    padding: 20px 0;
  }
`;

const OfficeImg = styled.img.attrs({ alt: '층별 이미지' })`
  width: 75%;

  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

export default OfficeMap;
