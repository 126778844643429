import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useOutletContext } from 'react-router-dom';

/* JS */
import * as Server from '../asset/js/Server';

/* 컴포넌트 */
import OfficeMap from 'components/office/floor';

const VinaOffice = () => {
  const onPhone = useOutletContext();

  const [mainItem, setMainItem] = useState({
    banner: [],
    office: [],
  });

  useEffect(() => {
    Server.sendGet(
      'vina/main/office',
      {
        bannerType: 'O',
      },
      getOfficeMainList
    ).then();
  }, []);

  const getOfficeMainList = res => {
    setMainItem(res['resultObject']);
  };

  return (
    <>
      <Content>
        <OfficeWrap>
          <OfficeSec>
            {mainItem?.banner?.map((item, index) => (
              <Inner onClick={onPhone} key={index}>
                <img src={item['bannerUrl']} alt="오피스 층별 이미지"/>
              </Inner>
            ))}
          </OfficeSec>
          <OfficeMap onClick={onPhone} item={mainItem?.office}/>
        </OfficeWrap>
      </Content>
    </>
  );
};

const Content = styled.div`
  z-index: 1;
  position: relative;
`;

const OfficeWrap = styled.div`
  position: relative;
`;

const OfficeSec = styled.div`
  position: relative;
  padding: 110px 0 80px;
  max-width: 1280px;
  margin: 0 auto;
  background: url('https://ust-vina.s3.ap-northeast-2.amazonaws.com/office/full_office_bg.png') 50% 50% no-repeat;
  background-size: cover;

  @media only screen and (max-width: 1240px) {
    padding: 40px 16px;
  }
`;

const Inner = styled.div`
  max-width: 1024px;
  margin: 0 auto;

  & img {
    width: 100%;
    cursor: pointer;
  }
`;
export default VinaOffice;
