import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

/* 슬라이드 - 스와이퍼 */
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';

const MainVisual = props => {
  const isDeskTop = useMediaQuery({ minWidth: 1241 });
  const isMobile = useMediaQuery({ maxWidth: 1240 });

  return (
    <>
      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
      >
        {isDeskTop &&
          props?.item?.map((item, index) => {
            if (item['mobileYn'] === 'N') {
              return (
                <SwiperSlide key={index}>
                  <VisualCon>
                    <VisualBox>
                      <VisualImg src={item?.['bannerUrl']}/>
                      <VisualDesc>
                        <span>{item?.['subTitle']}</span>
                        <strong>{item?.['title']}</strong>
                      </VisualDesc>
                    </VisualBox>
                  </VisualCon>
                </SwiperSlide>
              )
            }
          })
        }
        {isMobile &&
          props?.item?.map((item, index) => {
            if (item['mobileYn'] === 'Y') {
              return (
                <SwiperSlide key={index}>
                  <VisualCon>
                    <VisualBox>
                      <VisualImg src={item?.['bannerUrl']}/>
                      <VisualDesc>
                        <span>{item?.['subTitle']}</span>
                        <strong>{item?.['title']}</strong>
                      </VisualDesc>
                    </VisualBox>
                  </VisualCon>
                </SwiperSlide>
              )
            }
          })
        }
      </Swiper>
    </>
  );
};

/* 스타일 컴포넌트 */
const VisualCon = styled.div`
  position: relative;
  width: 100%;
`;

const VisualBox = styled.div`
  position: relative;
`;
const VisualDesc = styled.div`
  position: absolute;
  top: 80px;
  left: 16.66%;
  max-width: 1280px;

  & strong {
    margin-top: 7px;
    display: block;
    font-weight: 900;
    font-size: 64px;
    color: #ffffff;
  }

  & span {
    font-weight: 400;
    font-size: 30px;
    color: #ffffff;
  }

  & br {
    display: none;
  }

  @media only screen and (max-width: 1240px) {
    position: absolute;
    top: 0;
    left: 0;
    padding: 60px 16px;
    width: 100%;
    height: 100%;

    & strong {
      margin-top: 15px;
    }

    & span {
      display: inline-block;
      padding: 7px;
      background: #0067B0;
    }

    & br {
      display: block;
    }
  }

  @media only screen and (max-width: 640px) {
    & strong {
      font-size: 48px;
    }

    & span {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 460px) {
    & strong {
      font-size: 32px;
    }

    & span {
      font-size: 14px;
    }
  }
`;

const VisualImg = styled.img.attrs({ alt: '메인 비주얼 이미지' })`
  width: 100%;
`;

export default MainVisual;
