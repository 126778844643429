import React from 'react';
import styled from 'styled-components';

/* 슬라이드 - 스와이퍼 */
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const GalleryMR = props => {
  return (
    <>
      <Swiper
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        loop={true}
      >
        {props?.item?.map((v, i) => (
          <SwiperSlide key={i}>
            <OfficeImg src={v['imgUrl']}/>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

/* 스타일 컴포넌트 */
const OfficeImg = styled.img.attrs({ alt: '오피스 이미지' })`
  width: 100%;
`;

export default GalleryMR;
