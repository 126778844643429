import React from 'react';
import styled from 'styled-components';

const MainBanner = () => {
  return (
    <>
      <Container>
        <Inner>
          <Title data-aos="fade-up">
            <strong>Tiếp theo sự lựa chọn của quý khách.</strong>
            <span>Hãy cùng UST nâng cao vị thế doanh nghiệp của bạn.</span>
          </Title>
        </Inner>
      </Container>
    </>
  );
};

/* 스타일 컴포넌트 */

const Container = styled.div`
  position: relative;
  background: url('https://ust-vina.s3.ap-northeast-2.amazonaws.com/visual/visual_banner.png') 50% 50% no-repeat;
  background-size: cover;
`;

const Inner = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 130px 0 180px;

  @media only screen and (max-width: 1240px) {
    max-width: none;
    width: 100%;
    padding: 35px 0 50px;
  }
`;

const Title = styled.div`
  text-align: center;

  & strong {
    font-weight: 700;
    font-size: 44px;
    color: #ffffff;
  }

  & span {
    margin-top: 18px;
    display: block;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
  }

  @media only screen and (max-width: 1240px) {
    & strong {
      font-size: 24px;
    }

    & span {
      margin-top: 10px;
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 460px) {
    & strong {
      font-size: 18px;
    }

    & h4 {
      margin-top: 5px;
      font-size: 14px;
    }
  }
`;

export default MainBanner;
