import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterLayout = () => {
  return (
    <>
      <Footer>
        <Inner>
          <FootDepth>
            <Link to="/">
              <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/common/ust_w_ic.svg" alt="UST White"/>
            </Link>
          </FootDepth>
          <FootDepth>
            <FootAddress>
              <li>UST VINA Co.Ltd</li>
              <li>Address: 637 - 639 Nguyen Tat Thanh - Thanh Khe District - Da Nang</li>
              <li>Hotline: 0886 682 808 - 0236 381 1116</li>
              <li>Email: usttower.dn@gmail.com</li>
            </FootAddress>
          </FootDepth>
          <FootDepth>
            <SnsList>
              <li>
                <Link to="https://facebook.com/USTtower" target="_blank">
                  <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/common/facebook_ic.svg" alt="페이스북"/>
                </Link>
              </li>
              <li>
                <Link to="https://www.lazada.vn/shop/tob-store-vn/" target="_blank">
                  <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/common/lazada_ic.png" alt="라자다"/>
                </Link>
              </li>
              <li>
                <Link to="https://www.tiktok.com/@tob.vn" target="_blank">
                  <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/common/tiktok_ic.png" alt="틱톡"/>
                </Link>
              </li>
              <li>
                <Link to="https://shopee.vn/tob_officialstore" target="_blank">
                  <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/common/shopee_ic.png" alt="쇼피"/>
                </Link>
              </li>
            </SnsList>
          </FootDepth>
        </Inner>
      </Footer>
    </>
  );
};

const Footer = styled.div`
  position: relative;
  background: #2f2f2f;
`;

const Inner = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  padding: 85px 0 70px;
`;

const FootDepth = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + div {
    padding: 30px 0 0;
  }

  &:first-child {
    padding-bottom: 50px;
    border-bottom: 1px solid #ffffff;
  }
`;

const FootAddress = styled.ul`
  & li {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #ffffff;
    text-shadow: 0px 4px 4px #000000;
  }
`;

const SnsList = styled.ul`
  display: flex;
  align-items: center;
  gap: 0 10px;
`;

export default FooterLayout;
