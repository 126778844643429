import styled from 'styled-components';
import { useMediaQuery } from "react-responsive";

const TabTOA = props => {
  const isDeskTop = useMediaQuery({ minWidth: 1240 })
  const isMobile = useMediaQuery({ maxWidth: 1239 })

  return (
    <>
      <TabConBox>
        <Inner>
          <TabDesc>
            <h4>Từ sân bay quốc tế Đà Nẵng đến UST TOWER</h4>
            <p>Toà nhà văn phòng UST Tower tọa lạc tại trung tâm thành phố Đà Nẵng, cách sân bay quốc tế Đà Nẵng 2,4
              km, 8 phút đi ô tô hoặc xe máy.</p>
          </TabDesc>

          <WayVisual>
            <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/vina_city.png" alt="UST Tower "/>
          </WayVisual>

          {isDeskTop &&
            <HowToWay>
              <WayList>
                <WayInner>
                  <WayIc>
                    <WayImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/car_ic.svg"/>
                  </WayIc>
                  <WayDesc>
                    <strong>Phương tiện cá nhân</strong>
                    <p>
                      Duy Tân <em>▶</em> Nguyễn Văn Linh <em>▶</em> Thạc Gián <em>▶</em> Nguyễn Tri
                      Phương <em>▶</em> Nguyễn Tri Phương <em>▶</em> Lê Độ <em>▶</em> Xuân Hà <em>▶</em> Nguyễn Tất
                      Thành
                    </p>
                  </WayDesc>
                </WayInner>
              </WayList>
              <WayList>
                <WayInner>
                  <WayIc>
                    <WayImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/subway_ic.svg"/>
                  </WayIc>
                  <WayDesc>
                    <strong>Phương tiện công cộng</strong>
                    <p>Đi bộ(6 phút) ▶ Da Nang International Airport ▶ Đối Diện Công Viên 29/3 Điện Biên ▶ Đi bộ(14
                      phút) </p>
                  </WayDesc>
                </WayInner>
              </WayList>
              {/*<CloseButton onClick={() => props.function()}>
                <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/info_ic.svg" alt="화살표 IC"/>
                Đóng
              </CloseButton>*/}
            </HowToWay>
          }

          {isMobile &&
            <HowToWay>
              <WayList>
                <WayInner>
                  <WayMo>
                    <WayIc>
                      <WayImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/car_ic.svg"/>
                    </WayIc>
                    <strong>Phương tiện cá nhân</strong>
                  </WayMo>

                  <WayDesc>
                    <p>
                      Duy Tân <em>▶</em> Nguyễn Văn Linh <em>▶</em> Thạc Gián <em>▶</em> Nguyễn Tri
                      Phương <em>▶</em> Nguyễn Tri Phương <em>▶</em> Lê Độ <em>▶</em> Xuân Hà <em>▶</em> Nguyễn Tất
                      Thành
                    </p>
                  </WayDesc>
                </WayInner>
              </WayList>
              <WayList>
                <WayInner>
                  <WayMo>
                    <WayIc>
                      <WayImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/subway_ic.svg"/>
                    </WayIc>
                    <strong>Phương tiện công cộng</strong>
                  </WayMo>

                  <WayDesc>
                    <p>Đi bộ(6 phút) <em>▶</em> Da Nang International Airport <em>▶</em> Đối Diện Công Viên 29/3 Điện
                      Biên <em>▶</em> Đi bộ(14 phút) </p>
                  </WayDesc>
                </WayInner>
              </WayList>
            </HowToWay>
          }
        </Inner>
      </TabConBox>
    </>
  );
};

const TabConBox = styled.div`
  z-index: 1;
  position: relative;
`;

const Inner = styled.div`
  padding: 95px 0 40px;

  @media only screen and (max-width: 1240px) {
    max-width: 1240px;
    padding: 20px 0;
  }
`;

const TabDesc = styled.div`
  text-align: center;

  & h4 {
    font-weight: 500;
    font-size: 35px;
    color: #000000;
  }

  & p {
    margin-top: 15px;
    display: block;
    font-weight: 400;
    font-size: 24px;
    color: #000000;
  }

  @media only screen and (max-width: 1240px) {
    & h4 {
      font-size: 24px;
    }

    & p {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 720px) {
    & h4 {
      font-size: 18px;
    }

    & p {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 480px) {
    & h4 {
      font-size: 14px;
    }

    & p {
      font-size: 11px;
    }
  }
`;

const WayVisual = styled.div`
  margin-top: 50px;

  & img {
    width: 100%;
  }

  @media only screen and (max-width: 1240px) {
    margin-top: 10px;
  }
`;

const HowToWay = styled.ul`
  position: relative;
  max-width: 1280px;
  margin: 100px auto 0;

  @media only screen and (max-width: 1240px) {
    max-width: 1240px;
    margin-top: 20px;
  }

  @media only screen and (max-width: 1480px) {
    max-width: 480px;
    margin-top: 10px;
  }
`;

const WayList = styled.li`
  & + li {
    margin-top: 70px;
  }

  @media only screen and (max-width: 1240px) {
    & + li {
      margin-top: 25px;
    }
  }

  @media only screen and (max-width: 480px) {
    & + li {
      margin-top: 15px;
    }
  }
`;

const WayInner = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0 45px;

  @media only screen and (max-width: 1240px) {
    flex-direction: column;
    gap: 10px 0;
  }
`;

const WayMo = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;

  & strong {
    font-size: 18px;
    font-weight: 700;
    color: #000000;
  }
`

const WayIc = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: #0067b0;

  @media only screen and (max-width: 1240px) {
    min-width: 45px;
    width: 45px;
    height: 45px;

    & img {
      width: 60%;
    }
  }
`;

const WayImg = styled.img.attrs({ alt: '오는길 아이콘' })``;

const WayDesc = styled.div`
  & strong {
    font-weight: 700;
    font-size: 30px;
    color: #000000;
  }

  & p {
    margin-top: 10px;
    display: block;
    font-weight: 400;
    font-size: 30px;
    line-height: 1;
    color: #000000;

    & em {
      font-size: 15px;
      line-height: 42px;
    }
  }

  @media only screen and (max-width: 1240px) {
    & strong {
      font-size: 18px;
    }

    & p {
      margin: 0;
      font-size: 16px;
      line-height: 24px;

      & em {
        font-size: 11px;
        line-height: 24px;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    & strong {
      font-size: 14px;
    }

    & p {
      margin: 0;
      font-size: 13px;
      line-height: 16px;

      & em {
        font-size: 10px;
        line-height: 16px;
      }
    }
  }
`;

const CloseButton = styled.button`
  margin: 45px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 13px;
  padding: 0 16px;
  width: 180px;
  height: 55px;
  line-height: 55px;
  background: #0067b0;
  border-radius: 16px;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;

`;

export default TabTOA;
