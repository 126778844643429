import React from 'react';
import styled from 'styled-components';

const PhoneModal = props => {
  return (
    <>
      <Modal>
        <ModalBox>
          <Desc>
            <span>상담하기</span>
            <strong>0886 682 808</strong>
          </Desc>
          <Close onClick={() => props.function()}>
            <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/common/close_ic.svg" alt="닫기 버튼"/>
          </Close>
        </ModalBox>

        <Dimmed/>
      </Modal>
    </>
  );
};

/* 스타일 컴포넌트 */

const Modal = styled.div`
  z-index: 2;
  position: relative;
`;

const ModalBox = styled.div`
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 320px;
  background: #ffffff;
  border-radius: 10px;
`;

const Desc = styled.div`
  text-align: center;

  & span {
    font-weight: 500;
    font-size: 33px;
    color: #000000;
  }

  & strong {
    margin-top: 25px;
    display: block;
    font-weight: 700;
    font-size: 50px;
    color: #1e81ce;
  }
`;

const Close = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const Dimmed = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

export default PhoneModal;
