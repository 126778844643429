import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

/* JS */
import * as Common from '../../asset/js/Common';

/* 갤러리 컴포넌트*/
import GalleryOffice from './gallery/GalleryOfiice';
import GalleryMR from './gallery/GalleryMR';
import GalleryOS from './gallery/GalleryOS';

const MainGallery = props => {
  const [tabIndex, setTab] = useState(0);
  const [galleryItem, setGalleryItem] = useState({
    office: [],
    meeting: [],
    otherSpace: [],
  });

  useEffect(() => {
    if (!Common.isEmpty(props?.item)) {
      const item = {
        office: [],
        meeting: [],
        otherSpace: [],
      };

      props?.item?.forEach(v => {
        if (v.type === 'O') {
          item.office.push(v);
        } else if (v.type === 'M') {
          item.meeting.push(v);
        } else {
          item.otherSpace.push(v);
        }
      });

      setGalleryItem(item);
    }
  }, [props?.item]);

  const tabList = {
    0: <GalleryOffice item={galleryItem?.office}/>,
    1: <GalleryMR item={galleryItem?.meeting}/>,
    2: <GalleryOS item={galleryItem?.otherSpace}/>,
  };
  const changeTab = menuIndex => {
    setTab(menuIndex);
  };

  return (
    <>
      <Container>
        <Inner>
          <Title data-aos="fade-up">
            <strong>Gallery</strong>
            <h4>KHÔNG GIAN VĂN PHÒNG TẠI UST</h4>
          </Title>
          <GalleryBox data-aos="fade-up" data-aos-delay="150">
            <GalleryNav>
              <NavList tabSelect={`${(tabIndex === 0)}`} onClick={() => changeTab(0)}>
                OFFICE
              </NavList>
              <NavList tabSelect={`${(tabIndex === 1)}`} onClick={() => changeTab(1)}>
                MEETING ROOM
              </NavList>
              <NavList tabSelect={`${(tabIndex === 2)}`} onClick={() => changeTab(2)}>
                OTHER SPACE
              </NavList>
            </GalleryNav>
            <GalleryTab>{tabList[tabIndex]}</GalleryTab>
          </GalleryBox>
        </Inner>
      </Container>
    </>
  );
};

/* 스타일 컴포넌트 */

const Container = styled.div`
  position: relative;
`;

const Inner = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 100px 0 180px;

  @media only screen and (max-width: 1240px) {
    max-width: none;
    padding: 40px 16px 50px;
  }

`;

const Title = styled.div`
  text-align: center;

  & strong {
    font-weight: 500;
    font-size: 26px;
    color: #0067b0;
  }

  & h4 {
    margin-top: 18px;
    font-weight: 700;
    font-size: 44px;
    color: #1a1a1a;
  }

  @media only screen and (max-width: 1240px) {
    & strong {
      font-size: 18px;
    }

    & h4 {
      margin-top: 10px;
      font-size: 28px;
    }
  }

  @media only screen and (max-width: 460px) {
    & strong {
      font-size: 14px;
    }

    & h4 {
      margin-top: 5px;
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 360px) {
    & strong {
      font-size: 12px;
    }

    & h4 {
      margin-top: 5px;
      font-size: 16px;
    }
  }
`;

const GalleryBox = styled.div`
  margin-top: 100px;
  position: relative;
  width: 100%;

  @media only screen and (max-width: 1240px) {
    margin-top: 35px;
  }

  @media only screen and (max-width: 360px) {
    margin-top: 25px;
  }
`;

const GalleryNav = styled.ul`
  display: flex;
  align-items: center;
`;

const NavList = styled.li`
  padding: 25px 0;
  width: 33.33%;
  font-weight: 400;
  font-size: 28px;
  color: ${props => (props.tabSelect === 'true' ? '#ffffff' : '#000000')};
  background: ${props => (props.tabSelect === 'true' ? '#1e81ce' : '#ffffff')};
  text-align: center;
  cursor: pointer;

  &:first-child {
    border-radius: 30px 0 0 0;
  }

  &:last-child {
    border-radius: 0 30px 0 0;
  }

  @media only screen and (max-width: 1240px) {
    padding: 10px 0;
    font-size: 18px;
  }

  @media only screen and (max-width: 460px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 360px) {
    font-size: 10px;
  }
`;


const GalleryTab = styled.div`
  position: relative;
`;

export default MainGallery;
