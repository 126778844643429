import { useState } from 'react';
import styled from 'styled-components';

import TabWTC from './TabWay';
import TabTOA from './TabCity';
import TabAF from './TabFacilities';

const InfoTab = () => {
  const [tabIndex, setTab] = useState(1);

  const CloseTab = () => {
    setTab(0);
  };

  const tabList = {
    1: <TabTOA function={CloseTab}/>,
    2: <TabWTC function={CloseTab}/>,
    3: <TabAF function={CloseTab}/>,
  };

  const changeTab = menuIndex => {
    setTab(menuIndex);
  };

  return (
    <>
      <InfoTabSec>
        <TabNav>
          {/* 
           <TabItem tab={tabIndex === 1 ? 'true' : 'false'} onClick={() => changeTab(1)}>
           <ItemInner>
           <TabImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/info_tab01.png" />
           <DetailBtn>
           <DetailIc src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/info_ic.svg" />
           <span>Tìm hiểu thêm</span>
           </DetailBtn>
           </ItemInner>
           </TabItem>
           <TabItem tab={tabIndex === 2 ? 'true' : 'false'} onClick={() => changeTab(2)}>
           <ItemInner>
           <TabImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/info_tab02.png" />
           <DetailBtn>
           <DetailIc src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/info_ic.svg" />
           <span>Tìm hiểu thêm</span>
           </DetailBtn>
           </ItemInner>
           </TabItem>
           <TabItem tab={tabIndex === 3 ? 'true' : 'false'} onClick={() => changeTab(3)}>
           <ItemInner>
           <TabImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/info_tab03.png" />
           <DetailBtn>
           <DetailIc src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/info_ic.svg" />
           <span>Tìm hiểu thêm</span>
           </DetailBtn>
           </ItemInner>
           </TabItem>
           */}
          <TabItem $tab={tabIndex === 1 ? 'true' : 'false'} onClick={() => changeTab(1)}>
            <ItemInner>
              <TabTitle>The arteries<br/> Of A city</TabTitle>
            </ItemInner>
          </TabItem>
          <TabItem $tab={tabIndex === 2 ? 'true' : 'false'} onClick={() => changeTab(2)}>
            <ItemInner>
              <TabTitle>Way To Come</TabTitle>
            </ItemInner>
          </TabItem>
          <TabItem $tab={tabIndex === 3 ? 'true' : 'false'} onClick={() => changeTab(3)}>
            <ItemInner>
              <TabTitle>Administrative<br/> Facilities</TabTitle>
            </ItemInner>
          </TabItem>
        </TabNav>
        <TabContent>{tabList[tabIndex]}</TabContent>
      </InfoTabSec>
    </>
  );
};

const InfoTabSec = styled.div`
  z-index: 1;
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  padding: 40px 0 80px;

  @media only screen and (max-width: 1240px) {
    max-width: 1240px;
    padding: 20px 16px 10px;
  }
`;

const TabNav = styled.ul`
  position: relative;
  display: flex;

  & li + li {
    border-left: none;
  }
`;

const TabItem = styled.li`
  cursor: pointer;
  flex: 1;
  text-align: center;
  background: ${props => (props.$tab === 'true' ? '#0067B0' : '#e9e9e9')};
  padding: 12px 0;
  border: ${props => (props.$tab === 'true' ? '1px solid #0067B0' : '1px solid #b5b5b5')};

  &:hover {
    filter: grayscale(0);
  }

  & span {
    font-weight: ${props => (props.$tab === 'true' ? '700' : '300')};
    color: ${props => (props.$tab === 'true' ? '#ffffff' : '#7d7d7d')};

    & br {
      display: none;
    }
  }

  @media only screen and (max-width: 1240px) {
    padding: 12px 5px;

    & span {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 720px) {
    & span {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media only screen and (max-width: 480px) {
    & span {
      & br {
        display: block;
      }
    }
  }

  @media only screen and (max-width: 340px) {
    & span {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

const ItemInner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const TabTitle = styled.span`
  font-size: 28px;
`;

/* 
 const TabImg = styled.img.attrs({ alt: '탭 네비게이션' })`
 width: 100%;
 `;

 const DetailBtn = styled.button`
 position: absolute;
 bottom: 0;
 left: 0;
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 0 13px;
 width: 100%;
 height: 87px;
 background: #0067b0;
 text-align: center;

 & span {
 font-weight: 500;
 font-size: 30px;
 color: #ffffff;
 }
 `;

 const DetailIc = styled.img.attrs({ alt: '자세히보기 아이콘' })``;
 */

const TabContent = styled.div`
  position: relative;
`;

export default InfoTab;
