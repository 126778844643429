import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FloatLink = () => {
  return (
    <>
      <FloatBox>
        <FloatList>
          <FloatItem>
            <Link to="https://www.facebook.com/healthacleanvietnam" target="_blank">
              <FloatImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/common/facebook_ic.svg" />
            </Link>
          </FloatItem>
          <FloatItem>
            <Link to="https://www.tiktok.com/@healthacleanvietnam" target="_blank">
              <FloatImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/common/tiktok_ic.png" />
            </Link>
          </FloatItem>
          <FloatItem>
            <Link to="https://shopee.vn/healthaclean_vn" target="_blank">
              <FloatImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/common/shopee_ic.png" />
            </Link>
          </FloatItem>
        </FloatList>
      </FloatBox>
    </>
  );
};

const FloatBox = styled.div`
  z-index: 99;
  position: fixed;
  right: 20px;
  bottom: 40px;
`;

const FloatList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
`;

const FloatItem = styled.li`
  text-align: right;
`;

const FloatImg = styled.img.attrs({ alt: '플롯 이미지' })`
  width: 75%;
`;

export default FloatLink;
