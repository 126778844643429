import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const HeaderLayout = props => {
  const location = useLocation();

  return (
    <>
      <Header>
        <Inner>
          <HeadCon>
            <HeadLogo>
              <Link to="/">
                <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/common/ust_ic.svg" alt="UST"/>
              </Link>
            </HeadLogo>
            <HeadNav>
              <NavPath $current={location.pathname === '/office' ? 'true' : 'false'}>
                <Link to="/office">Văn Phòng</Link>
              </NavPath>
              <NavPath $current={location.pathname === '/cosmetic' ? 'true' : 'false'}>
                <Link to="/cosmetic">Sắc đẹp & Đời sống</Link>
              </NavPath>
              <NavPath $current={location.pathname === '/info' ? 'true' : 'false'}>
                <Link to="/info">Về UST VINA</Link>
              </NavPath>
              <NavPath>
                <Link onClick={() => props.function()}>Tư vấn miễn phí</Link>
              </NavPath>
            </HeadNav>
          </HeadCon>
        </Inner>
      </Header>
    </>
  );
};

const Header = styled.div`
  z-index: 10;
  position: sticky;
  top: 0;
  height: 80px;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0px 5px 5px 0 rgba(165, 165, 165, 0.15);
  padding: 0 18px;
`;

const Inner = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
`;

const HeadCon = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeadLogo = styled.div``;

const HeadNav = styled.ul`
  display: flex;
  align-items: center;
  gap: 0 85px;
`;

const NavPath = styled.li`
  position: relative;

  & a {
    font-size: 18px;
    font-weight: ${props => (props.$current === 'true' ? '900' : '500')};
    color: ${props => (props.$current === 'true' ? '#1E81CE' : '#000000')};
  }

  &:last-child {
    & a {
      display: block;
      width: 160px;
      height: 45px;
      line-height: 45px;
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      color: #ffffff;
      background: #0067b0;
      border-radius: 30px;
    }
  }
`;

export default HeaderLayout;
