import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const MainFloor = () => {
  return (
    <>
      <Container>
        <Inner>
          <FloorBox>
            <FloorNav>
              <Link to="/office" data-aos="fade-up" data-aos-delay="50">
                <NavImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/floor_info.svg"/>
              </Link>
            </FloorNav>
            <FloorBg>
              <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/visual/visual_floorBg.png" alt="플로어 백그라운드"/>
            </FloorBg>
          </FloorBox>
        </Inner>
      </Container>
    </>
  );
};

/* 스타일 컴포넌트 */

const Container = styled.div`
  position: relative;
`;

const Inner = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`;

const FloorBox = styled.div`
  display: flex;
`;

const FloorNav = styled.div`
  display: flex;
  flex-direction: column;
  width: 32.3%;
  padding: 25px 20px 15px;
  background: #383838;
`;

const NavImg = styled.img.attrs({ alt: '층 정보 버튼' })`
  width: 100%;
`;

const FloorBg = styled.div`
  width: 67.7%;

  & img {
    width: 100%;
    height: 100%;
  }
`;

export default MainFloor;
