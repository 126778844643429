import styled from 'styled-components';

const TabAF = props => {
  return (
    <>
      <TabConBox>
        <Inner>
          <FacilitiesList>
            <ListItem>
              <FacilitiesImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/facilities_01.png"/>
            </ListItem>
            <ListItem>
              <FacilitiesImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/facilities_02.png"/>
            </ListItem>
            <ListItem>
              <FacilitiesImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/facilities_03.png"/>
            </ListItem>
            <ListItem>
              <FacilitiesImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/facilities_04.png"/>
            </ListItem>
            <ListItem>
              <FacilitiesImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/facilities_05.png"/>
            </ListItem>
            <ListItem>
              <FacilitiesImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/facilities_06.png"/>
            </ListItem>
          </FacilitiesList>
          <FacilitiesDesc>
            <p>
              Toà nhà gồm 7 tầng nổi và bãi đỗ xe tách biệt là không gian làm việc của hơn 250 nhân viên trẻ trung từ đa
              dạng các chuyên ngành như thiết kế, đồ hoạ, marketing, vận tải... ngoài cung
              cấp cho các bạn không gian làm việc hiện đại-tiện lợi với giá hợp lý đi cùng không gian view biển tuyệt
              vời còn là nơi giao lưu kết nối giữa các doanh nghiệp trong và ngoài nước đang
              hiện hữu trong toà nhà.{' '}
            </p>
          </FacilitiesDesc>
          {/*<CloseButton onClick={() => props.function()}>
            <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/info_ic.svg" alt="화살표 IC"/>
            Đóng
          </CloseButton>*/}
        </Inner>
      </TabConBox>
    </>
  );
};

const TabConBox = styled.div`
  z-index: 1;
  position: relative;
`;

const Inner = styled.div`
  padding: 95px 0 40px;
  max-width: 1280px;
  margin: 0 auto;

  @media only screen and (max-width: 1240px) {
    padding: 20px 0;
    max-width: 1240px;
  }

  @media only screen and (max-width: 480px) {
    max-width: 480px;
  }
`;

const FacilitiesList = styled.ul`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 6px 6px;
`;

const ListItem = styled.li`
  width: calc(33.33% - 6px);
`;

const FacilitiesImg = styled.img.attrs({ alt: '건물 이미지' })`
  @media only screen and (max-width: 1240px) {
    width: 100%;
  }
`;

const FacilitiesDesc = styled.div`
  margin-top: 60px;

  & p {
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #000000;

    & strong {
      font-weight: 700;
    }
  }

  & p + p {
    margin-top: 30px;
  }

  @media only screen and (max-width: 1240px) {
    margin-top: 20px;

    & p {
      font-size: 18px;
      line-height: 24px;
    }


    @media only screen and (max-width: 480px) {
      margin-top: 10px;

      & p {
        font-size: 12px;
        line-height: 18px;
      }
    }
`;

const CloseButton = styled.button`
  margin: 45px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 13px;
  padding: 0 16px;
  width: 180px;
  height: 55px;
  line-height: 55px;
  background: #0067b0;
  border-radius: 16px;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;

  @media only screen and (max-width: 1240px) {
    display: none;
  }
`;

export default TabAF;
