import React from 'react';
import styled from 'styled-components';

const MainDataCnt = () => {
  return (
    <>
      <Container>
        <Inner>
          <DataCnt>
            <li data-aos="fade-up" data-aos-delay="100">
              <DataImgBox>
                <DataImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/contract_ic.svg"/>
              </DataImgBox>
              <DataCntDesc>
                <span>TÁI KÝ HỢP ĐỒNG</span>
                <strong>92%</strong>
              </DataCntDesc>
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              <DataImgBox>
                <DataImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/area_ic.svg"/>
              </DataImgBox>
              <DataCntDesc>
                <span>TIỆN ÍCH LÂN CẬN</span>
                <strong>250</strong>
              </DataCntDesc>
            </li>
            <li data-aos="fade-up" data-aos-delay="500">
              <DataImgBox>
                <DataImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/recommand_ic.svg"/>
              </DataImgBox>
              <DataCntDesc>
                <span>SỰ TÍN NHIỆM</span>
                <strong>94%</strong>
              </DataCntDesc>
            </li>
            <li data-aos="fade-up" data-aos-delay="700">
              <DataImgBox>
                <DataImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/airport_ic.svg"/>
              </DataImgBox>
              <DataCntDesc>
                <span>SÂN BAY</span>
                <strong>5 PHÚT</strong>
              </DataCntDesc>
            </li>
          </DataCnt>
        </Inner>
      </Container>
    </>
  );
};

/* 스타일 컴포넌트 */

const Container = styled.div`
  position: relative;
  padding-bottom: 180px;
  @media only screen and (max-width: 1240px) {
    padding: 0;
  }
`;

const Inner = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 170px 0;
  background: url('https://ust-vina.s3.ap-northeast-2.amazonaws.com/visual/visual_data.png') 50% 50% no-repeat;
  background-size: cover;

  @media only screen and (max-width: 1240px) {
    max-width: none;
    padding: 70px 16px;
  }

  @media only screen and (max-width: 460px) {
    padding: 35px 16px;
  }
`;

const DataCnt = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 150px;

  & li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 100px 0;
  }

  @media only screen and (max-width: 1240px) {
    gap: 0 20px;

    & li {
      gap: 15px 0;
      flex: 1;
    }
  }

  @media only screen and (max-width: 480px) {
    flex-wrap: wrap;
    gap: 30px 15px;

    & li {
      gap: 15px 0;
      flex: none;
      width: calc(50% - 15px);
    }
  }


`;

const DataImgBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding-top: 100%;
    border: 1px solid #ffffff;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  @media only screen and (max-width: 1240px) {
    width: 100%;
    height: auto;

    &::before {
      display: none;
    }
  }
`;

const DataImg = styled.img.attrs({ alt: '데이터 넘버 이미지' })`
  width: auto;

  @media only screen and (max-width: 1240px) {
    width: 25%;
  }
`;

const DataCntDesc = styled.div`
  text-align: center;

  & span {
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
  }

  & strong {
    margin-top: 5px;
    display: block;
    font-weight: 900;
    font-size: 50px;
    color: #ffffff;
  }

  @media only screen and (max-width: 1240px) {
    & span {
      font-size: 14px;
    }

    & strong {
      font-size: 24px;
    }
  }

  @media only screen and (max-width: 640px) {
    & span {
      display: block;
      font-size: 12px;
    }

    & strong {
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 480px) {
    & span {
      display: block;
      font-size: 12px;
    }

    & strong {
      font-size: 18px;
    }
  }
`;

export default MainDataCnt;
