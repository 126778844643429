import styled from 'styled-components';

const TabWTC = props => {
  return (
    <>
      <TabConBox>
        <Inner>
          <VisualCity>
            <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/vina_way.png" alt="UST City "/>
          </VisualCity>
          <CityDesc>
            <p>
              UST Tower là một toà nhà văn phòng nằm ở trung tâm thành phố Đà Nẵng, cách sân bay Quốc tế Đà Nẵng 2.4km
              với 8 phút đi ô tô hoặc xe máy. Toạ lạc ngay trên con đường biển Nguyễn Tất Thành
              với bãi biễn trải dài thơ mộng kết nối 3 quận trung tâm của thành phố là Hải Châu-Thanh Khê-Liên Chiểu có
              ý nghĩa huyết mạch của thành phố cả về hành chính [cách toà nhà hành chính 3.3km]
              dân cư lẫn vận tải kết nối [Cảng Liên Chiểu 14km]
            </p>
          </CityDesc>
          {/*<CloseButton onClick={() => props.function()}>
            <img src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/info/info_ic.svg" alt="화살표 IC"/>
            Đóng
          </CloseButton>*/}
        </Inner>
      </TabConBox>
    </>
  );
};

const TabConBox = styled.div`
  z-index: 1;
  position: relative;
`;

const Inner = styled.div`
  padding: 95px 0 40px;

  @media only screen and (max-width: 1240px) {
    padding: 20px 0;
  }
`;

const VisualCity = styled.div`
  & img {
    width: 100%;
  }
`;

const CityDesc = styled.div`
  margin: 60px auto 0;
  max-width: 1280px;
  text-align: center;

  & p {
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;

    & strong {
      font-weight: 700;
    }
  }

  @media only screen and (max-width: 1240px) {
    margin-top: 20px;
    max-width: 1240px;

    & p {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media only screen and (max-width: 480px) {
    margin-top: 10px;
    max-width: 480px;

    & p {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const CloseButton = styled.button`
  margin: 45px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 13px;
  padding: 0 16px;
  width: 180px;
  height: 55px;
  line-height: 55px;
  background: #0067b0;
  border-radius: 16px;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;

  @media only screen and (max-width: 1240px) {
    display: none;
  }
`;

export default TabWTC;
