import { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Server from 'asset/js/Server';

/* 섹션 컴포넌트 */
import VisualCosmetic from 'components/cosmetic/visualCosmetic';
import BrandStory from 'components/cosmetic/brandStory';
import CosmeticProduct from "../components/cosmetic/CosmeticProduct";

const VinaCosmetic = () => {
    const [mainItem, setMainItem] = useState({
        banner: [],
        subBanner: [],
    });

    const [productItem, setProductItem] = useState({
        product: [],
    })

    useEffect(() => {
        Server.sendGet(
            'vina/main/product',
            {
                bannerType: 'B',
                subBannerType: 'S',
            },
            getProductMainList
        ).then();
    }, []);

    useEffect(() => {
        Server.sendGet('tob/main/list', {
        }, getProductItemList).then();
    }, []);

    const getProductMainList = res => {
        setMainItem(res['resultObject']);
    };

    const getProductItemList = res => {
        setProductItem(res['resultObject']);
    };

  return (
      <CosmeticWrap>
          <VisualCosmetic item={mainItem?.banner}/>
          <BrandStory item={mainItem?.subBanner}/>
          <CosmeticProduct product={productItem?.product}/>
      </CosmeticWrap>
  );
};
const CosmeticWrap = styled.div`
  position:relative;
`



export default VinaCosmetic;
