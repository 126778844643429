import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import AOS from 'aos';
import 'aos/dist/aos.css';

/* 슬라이드 - 스와이퍼 */
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const BrandStory = props => {
  const isDeskTop = useMediaQuery({ minWidth: 1241 });
  const isMobile = useMediaQuery({ maxWidth: 1240 });

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <StorySec>
        {isDeskTop &&
          <Swiper loop={true} spaceBetween={14} slidesPerView={props?.item?.length}>
            {props?.item?.map((v, i) => (
              <SwiperSlide
                key={i}
                style={{
                  textAlign: 'center',
                  flex: 1
                }}
              >
                <StoryImg>
                  <StoryThumb src={v['bannerUrl']}/>
                </StoryImg>
                <StoryDesc>
                  <strong>{v['title']}</strong>
                  <span>{v['subTitle']}</span>
                </StoryDesc>
              </SwiperSlide>
            ))}
          </Swiper>
        }
        <StoryList>
          {isMobile &&
            props?.item?.map((v, i) => (
              <StoryItem key={i}>
                <StoryImg>
                  <StoryThumb src={v['bannerUrl']}/>
                </StoryImg>
                <StoryDesc>
                  <strong>{v['title']}</strong>
                  <span>{v['subTitle']}</span>
                </StoryDesc>
              </StoryItem>
            ))
          }
        </StoryList>
      </StorySec>
    </>
  );
};

const StorySec = styled.section`
  position: relative;
  max-width: 1280px;
  margin: 100px auto 0;

  @media only screen and (max-width: 1240px) {
    margin: 30px auto 0;
    padding: 0 16px;
  }
`;

const StoryList = styled.ul`
  display: flex;
  justify-content: center;
  
  @media only screen and (max-width: 1240px) {
    flex-wrap: wrap;
    gap: 15px 15px;
  }

  @media only screen and (max-width: 480px) {
    flex-wrap: wrap;
    gap: 10px 10px;
  }
`;

const StoryItem = styled.li`
  flex: 1;
  text-align: center;

  @media only screen and (max-width: 1240px) {
    flex: none;
    width: calc(50% - 7.5px);
  }

  @media only screen and (max-width: 480px) {
    width: calc(50% - 5px);
  }
`;

const StoryImg = styled.div`
  position: relative;
  width: 100%;
`;

const StoryThumb = styled.img.attrs({ alt: '브랜드 스토리 이미지' })`
  width: calc(100% - 20px);
`;

const StoryDesc = styled.div`
  margin-top: 30px;
  text-align: center;

  & strong {
    min-height: 65px;
    display: block;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #2e2e2e;
  }

  & span {
    margin-top: 28px;
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #656565;
  }

  @media only screen and (max-width: 1240px) {
    margin-top: 15px;

    & strong {
      min-height: 52px;
      font-size: 18px;
      line-height: 26px;
    }

    & span {
      margin-top: 15px;
      font-size: 15px;
      line-height: 24px;
    }

  }

  @media only screen and (max-width: 480px) {
    margin-top: 7.5px;

    & strong {
      min-height: 54px;
      font-size: 14px;
      line-height: 18px;
    }

    & span {
      margin-top: 7px;
      font-size: 12px;
      line-height: 16px;
    }

  }
`;

export default BrandStory;
