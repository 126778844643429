import styled from 'styled-components';

/* 슬라이드 - 스와이퍼 */
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';

const VisualCosmetic = props => {
  return (
    <>
      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
      >
        {props?.item?.map((v, i) => (
          <SwiperSlide key={i}>
            <VisualCon>
              <img src={v['bannerUrl']} alt="코스메틱 비주얼"/>
            </VisualCon>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

const VisualCon = styled.section`
  z-index: 4;
  position: relative;

  & img {
    width: 100%;
  }
`;
export default VisualCosmetic;
