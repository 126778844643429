import styled from 'styled-components';

/* 슬라이드 - 스와이퍼 */
import FloatLink from 'etc/float';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';

const HealthaClean = () => {
  return (
    <>
      <Inner>
        <ProdImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/office/healtha/healtha1.webp"/>
        <ProdImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/office/healtha/healtha2.webp"/>
        <ProdImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/office/healtha/healtha3.webp"/>
        <ProdImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/office/healtha/healtha4.webp"/>
      </Inner>
      <FloatLink/>
    </>
  );
};

const Inner = styled.div`
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 16px 80px;
`;
const ProdImg = styled.img.attrs({ alt: '제품 상세 타입 이미지' })`
  width: 100%;
  margin-bottom: 40px;
`;

export default HealthaClean;
