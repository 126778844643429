import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const globalStyles = createGlobalStyle`
     ${reset};
     * {
     box-sizing: border-box;
     line-height: 1.2;
     letter-spacing: -0.03em;
     word-break: keep-all;
   }

   html,
   body,
   div,
   dl,
   dt,
   dd,
   ul,
   ol,
   li,
   h1,
   h2,
   h3,
   h4,
   h5,
   h6,
   pre,
   code,
   form,
   fieldset,
   legend,
   input,
   textarea,
   p,
   blockquote,
   th,
   td,
   img {
     margin: 0;
     padding: 0;
   }
   html {
     width: 100%;
   }
   body#popup {
     min-width: 0;
   }
   li {
     list-style: none;
   }
   img,
   fieldset {
     border: none;
     vertical-align: middle;
   }
   table {
     width: 100%;
     border: 0;
     border-spacing: 0;
     border-collapse: collapse;
   }
   caption {
     display: none;
   }
   th,
   td {
     border: 0;
     vertical-align: top;
   }

   div {
    -webkit-border: transparent;
    -moz-border: transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
   }
   button {
     overflow: visible;
     padding: 0;
     margin: 0;
     border: 0;
     cursor: pointer;
     background: transparent;
     -webkit-border-radius: 0;
     -moz-border-radius: 0;
   }
   hr.layout {
     display: none;
   }
   select {  -webkit-appearance: none;}

   a {
     text-decoration: none;
     color: #000;
   }
   a:hover {
     text-decoration: none;
   }
   a:active {
     text-decoration: none;
   }
   input:-webkit-autofill { -webkit-box-shadow: 0 0 0 30px #fff inset ; -webkit-text-fill-color: #000; }
  input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active { transition: background-color 5000s ease-in-out 0s; }
   
   body {
    font-family: "Pretendard";
   }
   
   .swiper-pagination-bullet {
    background:#ffffff;
    opacity:1;
   }

   .swiper-pagination-bullet-active {
    background:#1e81ce;
   }

   .swiper .swiper-button-prev, .swiper .swiper-button-next {
    color:#ffffff;
   }

   .swiper .swiper-button-prev {
    left:9.37%;
   }

   .swiper .swiper-button-next {
    right:9.37%;
   }

   a[href^="http://maps.google.com/maps"]{display:none !important}
   a[href^="https://maps.google.com/maps"]{display:none !important}

   .gmnoprint a, .gmnoprint span, .gm-style-cc {
      display:none;
   }
   .gmnoprint div {
      background:none !important;
   }
`;
export default globalStyles;
