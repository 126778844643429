import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

/* Layout Components */
import HeaderLayout from './header';
import FooterLayout from './footer';
import MoHeaderLayout from './MoHeader'
import MoFooterLayout from "./MoFooter";

/* 모달 컴포넌트 */
import PhoneModal from 'components/modal/phone';
import { useMediaQuery } from "react-responsive";

const VinaLayout = () => {
  const isDeskTop = useMediaQuery({ minWidth: 1241 })
  const isMobile = useMediaQuery({ maxWidth: 1240 })

  const [phone, setPhone] = useState(false);

  useEffect(() => {
    if (phone === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [phone]);

  const ClosePhone = () => {
    setPhone(false);
  };

  const onPhone = () => {
    setPhone(true);
  };

  return (
    <>
      {/* 헤더 */}
      {isDeskTop && <HeaderLayout function={onPhone}/>}
      {isMobile && <MoHeaderLayout/>}

      {/* 페이지 */}
      <Outlet context={onPhone}/>

      {/* 모달 */}
      {phone && <PhoneModal function={ClosePhone}/>}

      {/* 푸터 */}
      {isDeskTop && <FooterLayout/>}
      {isMobile && <MoFooterLayout/>}
    </>
  );
};

export default VinaLayout;
