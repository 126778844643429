import styled from 'styled-components';
import ProductItem from "./ProductItem";

export default function CosmeticProduct ({ product }) {
    return (
        <ProductCon>
            <div className="sec_head">
                <h1>Cosmetic Product</h1>
            </div>
            <div className="inner">
                <ul>
                    {product.map((item, index) => (
                        <ProductItem key={index} item={item} />
                    ))}
                </ul>
            </div>
        </ProductCon>
    )
}

const ProductCon = styled.section`
  position: relative;
  padding: 70px 0 140px;
  
  .sec_head {
    display:none;
  }
  
  .inner {
    max-width:1280px;
    margin:0 auto;
    width:95%;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 30px;

    li {
      width: calc(33.33% - 20px);

      .prod_img {
        width: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }

      .prod_tit {
        margin-top: 18px;
        text-align: center;

        strong {
          font-weight: 400;
          font-size: var(--prd-txt);
          color: var(--color-black);
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 70px 0 35px;

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 18px 18px;

      li {
        width: calc(50% - 9px);

        .prod_img {
          width: 100%;

          img {
            width: 100%;
            height: auto;
          }
        }

        .prod_tit {
          margin-top: 10px;
        }
      }
    }
  }
`;