import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

/* JS */
import * as Common from 'asset/js/Common';

/* 슬라이드 - 스와이퍼 */
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const MainLease = props => {
  const navigate = useNavigate();

  const isDeskTop = useMediaQuery({ minWidth: 1241 });
  const isMobile = useMediaQuery({ maxWidth: 1240 });

  const [officeItem, setOfficeItem] = useState([]);

  useEffect(() => {
    if (!Common.isEmpty(props?.item)) {
      setOfficeItem(props?.item);
    }
  }, [props?.item]);

  const GoOffice = () => {
    navigate('/office');
  };

  return (
    <>
      <Container>
        <Inner>
          <Title data-aos="fade-up">
            <strong>Lease Office Space</strong>
            <h4>VĂN PHÒNG CÓ THỂ THUÊ</h4>
          </Title>
          <LeaseBox>
            {isDeskTop &&
              <Swiper loop={true} spaceBetween={14} slidesPerView={officeItem.length === 1 ? 1 : 2}>
                {officeItem?.map((v, i) => (
                  <SwiperSlide
                    key={i}
                    style={{
                      border: '1px solid #cacaca',
                      boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.3)'
                    }}
                  >
                    <LeaseImg src={v['imgUrl']}/>
                    <LeaseDesc>
                      <LeaseInfo>
                        <FloorInfo>
                          <Icon src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/floor_ic.svg"/>
                          <span>{v['floor']}F - Phòng {v['room']}</span>
                        </FloorInfo>
                        <strong>{v['area']}㎡</strong>
                      </LeaseInfo>
                      <LeaseInfo>
                        <Facility>
                          <li>
                            <FacilityIc src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/desk_ic.svg"/>
                            <span>{v['desk']}</span>
                          </li>
                          <li>
                            <FacilityIc src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/chair_ic.svg"/>
                            <span>{v['chair']}</span>
                          </li>
                          <li>
                            <FacilityIc src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/air_ic.svg"/>
                            <span>{v['air']}</span>
                          </li>
                        </Facility>
                        <ConsultBtn onClick={() => props.function()}>Tư vấn miễn phí</ConsultBtn>
                      </LeaseInfo>
                    </LeaseDesc>
                  </SwiperSlide>
                ))}
              </Swiper>
            }
            <LeaseList>
              {isMobile &&
                officeItem?.map((v, i) => (
                  <ListItem key={i} data-aos="fade-up" data-aos-delay="100">
                    <LeaseImg src={v['imgUrl']}/>
                    <LeaseDesc>
                      <LeaseInfo>
                        <FloorInfo>
                          <Icon src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/floor_ic.svg"/>
                          <span>{v['floor']}F - Phòng {v['room']}</span>
                        </FloorInfo>
                        <strong>{v['area']}㎡</strong>
                      </LeaseInfo>
                      <LeaseInfo>
                        <Facility>
                          <li>
                            <FacilityIc src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/desk_ic.svg"/>
                            <span>{v['desk']}</span>
                          </li>
                          <li>
                            <FacilityIc src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/chair_ic.svg"/>
                            <span>{v['chair']}</span>
                          </li>
                          <li>
                            <FacilityIc src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/air_ic.svg"/>
                            <span>{v['air']}</span>
                          </li>
                        </Facility>
                        <ConsultBtn onClick={() => props.function()}>Tư vấn miễn phí</ConsultBtn>
                      </LeaseInfo>
                    </LeaseDesc>
                  </ListItem>
                ))
              }
            </LeaseList>
            <ViewBtn data-aos="fade-up" data-aos-delay="350" onClick={GoOffice}>
              VIEW MORE
            </ViewBtn>
          </LeaseBox>
        </Inner>
      </Container>
    </>
  );
};

/* 스타일 컴포넌트 */
const Container = styled.div`
  position: relative;
`;

const Inner = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding-bottom: 150px;

  @media only screen and (max-width: 1240px) {
    padding-bottom: 50px;
  }
`;

const Title = styled.div`
  text-align: center;

  & strong {
    font-weight: 500;
    font-size: 26px;
    color: #0067b0;
  }

  & h4 {
    margin-top: 18px;
    font-weight: 700;
    font-size: 44px;
    color: #1a1a1a;
  }

  @media only screen and (max-width: 1240px) {
    & strong {
      font-size: 18px;
    }

    & h4 {
      margin-top: 10px;
      font-size: 28px;
    }
  }

  @media only screen and (max-width: 460px) {
    & strong {
      font-size: 14px;
    }

    & h4 {
      margin-top: 5px;
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 360px) {
    & strong {
      font-size: 12px;
    }

    & h4 {
      margin-top: 5px;
      font-size: 16px;
    }
  }
`;

const LeaseBox = styled.div`
  margin: 130px 0 0;

  @media only screen and (max-width: 1240px) {
    margin: 45px 0 0;
    padding: 0 16px;
  }

  @media only screen and (max-width: 360px) {
    margin: 25px 0 0;
  }
`;

const LeaseList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media only screen and (max-width: 1240px) {
    flex-direction: column;
    gap: 40px 0;
  }
`;

const ListItem = styled.li`
  border: 1px solid #cacaca;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.3);

  @media only screen and (max-width: 1240px) {
    width: 100%;
  }

  @media only screen and (max-width: 460px) {
    & div + div {
      gap: 20px 0;
      flex-direction: column;
    }
  }
`;

const LeaseImg = styled.img.attrs({ alt: '임대오피스 이미지' })`
  width: 100%;
`;

const LeaseInfo = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & strong {
    font-weight: 500;
    font-size: 28px;
    color: #000000;
  }

  &:last-child {
    border-top: 1px solid #d9d9d9;
  }

  @media only screen and (max-width: 640px) {
    padding: 10px 16px;

    & strong {
      font-size: 18px;
    }


`;

const LeaseDesc = styled.div``;

const FloorInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0 5px;

  & span {
    font-weight: 400;
    font-size: 28px;
    color: #000000;
  }

  @media only screen and (max-width: 640px) {
    & span {
      font-size: 18px;
    }
`;

const Icon = styled.img.attrs({ alt: '임대오피스 이미지' })`
  width: auto;

  @media only screen and (max-width: 640px) {
    width: 36px;
  }

  @media only screen and (max-width: 460px) {
    width: 24px;
  }
`;

const Facility = styled.ul`
  display: flex;
  align-items: center;
  gap: 0 60px;

  & li {
    display: flex;
    align-items: center;
    gap: 0 6px;

    & span {
      font-weight: 500;
      font-size: 24px;
      color: #515151;
    }
  }

  @media only screen and (max-width: 640px) {
    gap: 0 20px;

    & li {
      & span {
        font-size: 18px;
      }
    }
  }
`;

const FacilityIc = styled.img.attrs({ alt: '임대오피스 정보 아이콘 ' })`
  width: auto;

  @media only screen and (max-width: 640px) {
    width: 36px;
  }

  @media only screen and (max-width: 640px) {
    width: 24px;
  }
`;

const ConsultBtn = styled.button`
  width: 160px;
  height: 45px;
  line-height: 45px;
  background: #0067b0;
  border-radius: 30px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  text-align: center;

  @media only screen and (max-width: 640px) {
    width: 125px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }
`;

const ViewBtn = styled.button`
  margin: 60px auto 0;
  display: block;
  width: 210px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border: 1px solid #4d4d4d;
  border-radius: 100px;
  font-weight: 400;
  font-size: 20px;
  color: #202020;
  transition: all 0.3s;

  &:active,
  &:hover {
    border-color: #0067b0;
    background-color: #0067b0;
    color: #ffffff;
    font-weight: 700;
  }

  @media only screen and (max-width: 640px) {
    margin: 25px auto 0;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
  }
`;

export default MainLease;
